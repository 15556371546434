import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps } from 'react-admin';
import { isToday, isTomorrow, isYesterday } from 'date-fns';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: (props: any) => props.align,
    marginTop: (props: any) => props.mt,
    marginLeft: (props: any) => props.ml,
    fontStyle: (props: any) => props.fontStyle,
  },
});

const getTime = (date: Date) => {
  return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

type DateInColumnProps = FieldProps & {
  locales?: string;
  options?: Intl.DateTimeFormatOptions;
  source: string;
  label?: string;
  align?: 'left' | 'right';
  mt?: string;
  ml?: string;
  fontStyle?: string;
};

function DateInColumn(props: DateInColumnProps) {
  const { record, source } = props;
  const classes = useStyles(props);
  const showTime = source === 'inspection_date';

  if (!record || !record[source]) {
    return (
      <div className={classes.root}>
        <span>-</span>
      </div>
    );
  }

  const date = new Date(record[source]);
  if (date.toString() === 'Invalid Date') {
    return (
      <div className={classes.root}>
        <span>Invalid date</span>
      </div>
    );
  }

  let displayText = '';
  if (isToday(date)) {
    displayText = showTime ? `Today @ ${getTime(date)}` : 'Today';
  } else if (isTomorrow(date)) {
    displayText = showTime ? `Tomorrow @ ${getTime(date)}` : 'Tomorrow';
  } else if (isYesterday(date)) {
    displayText = showTime ? `Yesterday @ ${getTime(date)}` : 'Yesterday';
  } else {
    displayText = showTime ? `${date.toLocaleDateString()} @ ${getTime(date)}` : date.toLocaleDateString();
  }

  return (
    <div className={classes.root}>
      <span>{displayText}</span>
    </div>
  );
}

export default DateInColumn;
