import { getFingerprint, getFingerprintData } from '@thumbmarkjs/thumbmarkjs';

// Import package information
// @ts-ignore
const packageInfo = require('../../../package.json');

// Create a fallback fingerprint from the app name and version
const fallbackFingerprint = `${packageInfo.name}@${packageInfo.version}`;

/**
 * Get a stable device fingerprint to identify browsers/devices using ThumbmarkJS
 * @returns Promise resolving to an object containing fingerprint and metadata
 */
export const getDeviceFingerprint = async (): Promise<{ fingerprint: string; metadata: any }> => {
  try {
    // Get fingerprint hash from ThumbmarkJS
    const fingerprint = await getFingerprint();
    
    // Get the full fingerprint data from ThumbmarkJS
    const fullData = await getFingerprintData();
    
    // Extract only the needed metadata objects
    const metadata = {
      system: fullData.system || {},
      locales: fullData.locales || {},
      permissions: fullData.permissions || {},
      plugins: fullData.plugins || {}
    };
    
    return {
      fingerprint,
      metadata
    };
  } catch (error) {
    console.error('Error generating ThumbmarkJS fingerprint:', error);
    
    // Fallback to app name and version in case of errors
    return {
      fingerprint: fallbackFingerprint,
      metadata: {
        system: { name: 'unknown', version: 'unknown', os: 'unknown' },
        locales: {},
        permissions: {},
        plugins: {}
      }
    };
  }
}; 